import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeviceService } from 'wsl-device';
import {WSLMaterializeHelper} from 'wsl-shared';

@Component({
  selector: 'wsl-select-device',
  templateUrl: './select-device.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDeviceComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string;
  @Input() prefix = '';
  @Input() id: number;
  @Input() name: string;
  @Input() filter = {};
  @Input()
  set disabled(isDisable: boolean) {
    this.readonly = isDisable;
    if (isDisable) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.form.updateValueAndValidity();
  }
  @Output() selectItem = new EventEmitter<number>();

  readonly = true;
  /** @internal */
  form = new FormGroup({
    device_name: new FormControl(''),
    device_id: new FormControl(null)
  });
  /** @internal */
  devices = [];
  /** @internal */
  selectedDeviceIndex = 0;
  /** @internal */
  deviceFocus = new EventEmitter<boolean>();
  /** @internal */
  loadingDevices = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor( private deviceService: DeviceService,
               private chr: ChangeDetectorRef) { }

  ngOnInit() {
    this.form.get('device_id').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(100), distinctUntilChanged())
      .subscribe(val => {
        this.selectItem.emit(val);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id) {
      if (this.id) {
        this.form.get('device_id').setValue(this.id);
        if (this.devices.length > 0) {
          const d = this.devices.find(dev => dev.id === this.id);
          this.form.get('device_name').setValue(d ? d.name : '');
        } else {
          this.loadDevice();
        }
      }
    }
    if (changes.name) {
      if (this.form.get('device_name').value !== name) {
        this.form.get('device_name').setValue(this.name);
      }
    }
    if (changes.filter && this.devices.length > 0) {
      this.onClearDevice();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  // Autocomplete Device
  onSearchDevice(search) {
    this.selectedDeviceIndex = 0;
    this.form.get('device_name').setValue(search);
    this.loadDeviceSuggestions(search);
    this.deviceFocus.emit(true);
  }

  onEnterKeyDevice(keyCode) {
    switch (keyCode) {
      case 13:
        // enter

        this.onSelectDevice(this.devices[this.selectedDeviceIndex]);
        break;
      case 38:
        // up
        if (this.selectedDeviceIndex > 0) {
          this.selectedDeviceIndex--;
        }
        break;
      case 40:
        // down
        if (this.selectedDeviceIndex < this.devices.length) {
          this.selectedDeviceIndex++;
        }
        break;
      default:
        this.deviceFocus.emit(false);
    }
  }

  onSelectDevice(val) {
    if (val) {
      this.selectedDeviceIndex = this.devices.indexOf(val);
      this.form.get('device_name').setValue(val.name);
      this.form.get('device_id').setValue(val.id);
      this.form.updateValueAndValidity();
      this.deviceFocus.emit(false);
    } else {
      this.onClearDevice();
    }
  }

  onClearDevice() {
    this.selectedDeviceIndex = 0;
    this.form.get('device_name').setValue('');
    this.form.get('device_id').setValue(null);
    this.loadDeviceSuggestions('');
    this.form.updateValueAndValidity();
    this.deviceFocus.emit(false);
  }

  private loadDeviceSuggestions(search) {
    const match = /^№([0-9]+).*$/.exec(search);
    if (match) {
      search = match[1];
    }
    this.loadingDevices = true;
    this.devices = [];
    const filter = this.filter ? this.filter : {};
    this.deviceService.getMany({...filter, search, limit: 25, offset: 0})
      .subscribe(
        res => {
          if (!res.error) {
            this.devices = res.items.map(val => ({...val, name: `${val.alias ? val.alias : ('№' + val.serial_num)}`}))
              .sort((a, b) => DeviceService.sort(a, b));
            this.loadingDevices = false;
            this.chr.markForCheck();
          }
        },
        err => {
          this.loadingDevices = false;
          this.chr.markForCheck();
        }
      );
  }

  private loadDevice() {
    this.loadingDevices = true;
    this.devices = [];
    this.deviceService.get(this.id)
      .subscribe(
        res => {
          if (!res.error) {
            this.devices = [{...res.item, name: `${res.item.alias ? res.item.alias : ('№' + res.item.serial_num)}`}];
            this.onSelectDevice(this.devices[0]);
            this.loadingDevices = false;
            this.chr.markForCheck();
          }
        },
        err => {
          WSLMaterializeHelper.toast({html: this.label + ' не найден. Возможно, он скрыт'});
          this.loadingDevices = false;
          this.chr.markForCheck();
        }
      );
  }

  trackById(index: number, obj: any) {
    return obj.id;
  }

}
