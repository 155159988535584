import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromDeviceSensor from '@store/device-sensor/device-sensor.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DeviceSensorEffects } from '@store/device-sensor/device-sensor.effects';
import { WslSharedModule } from 'wsl-shared';
import { AppCommonModule } from '@common/app-common.module';
import { DeviceSensorService } from './services/device-sensor.service';
import { DeviceSensorFormComponent } from './components/device-sensor-form/device-sensor-form.component';
import { DeviceSensorComponent } from './components/device-sensor/device-sensor.component';
import {DeviceSensorInfoComponent} from '@app/device-sensor/components/device-sensor-info/device-sensor-info.component';
import {SensorModelsComponent} from '@app/device-sensor/components/sensor-models/sensor-models.component';
import {SensorModelComponent} from '@app/device-sensor/components/sensor-model/sensor-model.component';
import {SensorModelService} from '@app/device-sensor/services/sensor-model.service';
import {MatTableModule} from '@angular/material';

const COMPONENTS = [
  DeviceSensorFormComponent,
  DeviceSensorComponent,
  DeviceSensorInfoComponent,
  SensorModelsComponent,
  SensorModelComponent
];

@NgModule({
  imports: [
    WslSharedModule,
    AppCommonModule,
    MatTableModule,
    StoreModule.forFeature('deviceSensor', fromDeviceSensor.reducer),
    EffectsModule.forFeature([DeviceSensorEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    DeviceSensorService,
    SensorModelService
  ]
})
export class DeviceSensorModule {
}
