import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWSLObject } from '../../models/object';

@Component({
  selector: 'wsl-objects-table',
  templateUrl: './objects-table.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectsTableComponent implements OnInit {
  @Input() objects: IWSLObject[];
  @Input() selectedObjectID: number;
  @Input() theadHeight: number;
  @Output() selectItem = new EventEmitter<number>();

  /** @internal */
  scrollTop = 0;

  constructor() {
  }

  ngOnInit() {
  }

  onScroll(st) {
    this.scrollTop = st;
  }

  trackById(index: number, obj: any): any {
    return obj.tab_id;
  }

}
