import { OnInit } from '@angular/core';
import { ConnectionType } from '../../enums/connection-type.enum';
import { LoraNodeClasses } from '../../enums/lora-node-class.enum';
import { LoRaWANProtocols } from '../../enums/lorawan-protocol.enum';
import { CommunicatorService } from '../../services/communicator.service';
var CommunicatorRowComponent = /** @class */ (function () {
    function CommunicatorRowComponent() {
    }
    CommunicatorRowComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(CommunicatorRowComponent.prototype, "object", {
        get: function () {
            var _this = this;
            return this.communicator.object_id && this.objects ?
                this.objects.find(function (o) { return o.id === _this.communicator.object_id; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorRowComponent.prototype, "isLora", {
        get: function () {
            return this.communicator.network.channel_id === ConnectionType.lora;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorRowComponent.prototype, "isTCP", {
        get: function () {
            return this.communicator.network.channel_id === ConnectionType.tcp || this.communicator.network.channel_id === ConnectionType.tcp_gprs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorRowComponent.prototype, "isGPRS", {
        get: function () {
            return this.communicator.network.channel_id === ConnectionType.gprs || this.communicator.network.channel_id === ConnectionType.tcp_gprs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorRowComponent.prototype, "loraNodeClass", {
        get: function () {
            var _this = this;
            return this.communicator.network.lora.lora_class_id ? LoraNodeClasses.find(function (c) { return c.id === +_this.communicator.network.lora.lora_class_id; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorRowComponent.prototype, "loraProtocolVersion", {
        get: function () {
            var _this = this;
            return this.communicator.network.lora.lora_version_id ? LoRaWANProtocols.find(function (c) { return c.id === +_this.communicator.network.lora.lora_version_id; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorRowComponent.prototype, "connection", {
        get: function () {
            if (this.communicator) {
                var nt = CommunicatorService.getConnectionType(this.communicator);
                if (!nt) {
                    console.error(this.communicator);
                }
                else {
                    return {
                        icon: nt.icon,
                        name: nt.name,
                        describe: CommunicatorService.getConnectionParameters(this.communicator)
                    };
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return CommunicatorRowComponent;
}());
export { CommunicatorRowComponent };
