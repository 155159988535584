import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWSLRoom } from '../../models/room';
import { WSLMaterializeHelper } from 'wsl-shared';
import { IWSLAsyncErrors } from 'wsl-core';
import { IWSLObject } from '@app/object/models/object';

@Component({
  selector: 'wsl-room-list',
  templateUrl: './room-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomListComponent implements OnInit {
  @Input() object: IWSLObject;
  @Input() rooms: IWSLRoom[];
  @Input() asyncErrors: IWSLAsyncErrors;
  @Input() disabled = true;
  @Output() selectItem = new EventEmitter<IWSLRoom>();
  @Output() add = new EventEmitter<IWSLRoom>();
  @Output() massAdd = new EventEmitter<IWSLRoom[]>();
  @Output() delete = new EventEmitter<number>();
  @Output() massDelete = new EventEmitter<number[]>();
  @Output() generateCodes = new EventEmitter();

  /** @internal */
  apartmentNumBeg: number = null;
  /** @internal */
  apartmentNumEnd: number = null;
  /** @internal */
  nonResidentialName = '';

  constructor() {
  }

  ngOnInit() {
  }

  get apartments() {
    return this.rooms ? this.rooms.filter(r => r.residential) : [];
  }

  get specials() {
    return this.rooms ? this.rooms.filter(r => !r.residential) : [];
  }


  addApartments() {
    if (this.apartmentNumBeg == null || this.apartmentNumBeg < 1) {
      WSLMaterializeHelper.toast({html: 'Задайте начало диапазона квартир'});
      return;
    }
    if (this.apartmentNumEnd == null || this.apartmentNumEnd < 1) {
      const id = this.apartments.findIndex(item => parseInt(item.name, 10) === this.apartmentNumBeg);
      if (id < 0) {
        this.addRoom({name: this.apartmentNumBeg.toString(), residential: true});
      }
      this.apartmentNumBeg = null;
      return;
    }
    if (this.apartmentNumBeg > this.apartmentNumEnd) {
      WSLMaterializeHelper.toast({html: 'Начало диапазона квартир должно быть меньше конца'});
      return;
    }
    const data = [];
    for (let i = this.apartmentNumBeg; i <= this.apartmentNumEnd; i++) {
      if (this.apartments.findIndex(item => parseInt(item.name, 10) === i) < 0) {
        data.push({name: i, residential: true});
      }
    }
    this.addRooms(data);
    this.apartmentNumBeg = null;
    this.apartmentNumEnd = null;
  }

  removeApartments() {
    if (this.apartmentNumBeg == null || this.apartmentNumBeg < 1) {
      WSLMaterializeHelper.toast({html: 'Задайте начало диапазона квартир'});
      return;
    }
    if (this.apartmentNumEnd == null || this.apartmentNumEnd < 1) {
      const app = this.apartments.find(item => item.name === this.apartmentNumBeg.toString(10));
      if (app) {
        this.deleteRoom(app.id);
      }
      this.apartmentNumBeg = null;
      return;
    }
    if (this.apartmentNumBeg > this.apartmentNumEnd) {
      WSLMaterializeHelper.toast({html: 'Начало диапазона квартир должно быть меньше конца'});
      return;
    }
    const data = [];
    for (let i = this.apartmentNumBeg; i <= this.apartmentNumEnd; i++) {
      const app = this.apartments.find(item => item.name === i.toString(10));
      if (app) {
        data.push(app.id);
      }
    }
    this.deleteRooms(data);
    this.apartmentNumBeg = null;
    this.apartmentNumEnd = null;
  }

  addNonResidential() {
    if (this.nonResidentialName !== '') {
      this.addRoom({name: this.nonResidentialName, residential: false});
    }
    this.nonResidentialName = '';
  }

  removeNonResidential(item) {
    const room = this.specials.find(it => it.id === item.id);
    if (room) {
      this.deleteRoom(room.id);
    }
  }

  generateActivationCodes() {
    if (this.object) {
      this.generateCodes.emit(this.object.id);
    }
  }

  private addRoom(obj: IWSLRoom) {
    this.add.emit({...obj, object_id: this.object.id});
  }

  private addRooms(data: Array<IWSLRoom>) {
    this.massAdd.emit(data.map(room => ({...room, object_id: this.object.id})));
  }

  private deleteRoom(id: number) {
    this.delete.emit(id);
  }

  private deleteRooms(data: Array<number>) {
    this.massDelete.emit(data);
  }

}
