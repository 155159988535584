import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ObjectPermissions, UserProfileService } from 'wsl-ek-core';
import { IWSLObject } from '../../models/object';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[wsl-object-row]',
  templateUrl: './object-row.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectRowComponent implements OnInit {
  @Input() object: IWSLObject;
  @Input() selectedObjectID: number;

  disabled = true;
  /** @internal */
  objectTypes: Array<{id: number, name: string}> = [];

  constructor(private route: ActivatedRoute, private userProfileService: UserProfileService) {
  }

  ngOnInit() {
    this.disabled = !this.userProfileService.hasPermission(ObjectPermissions.update);
    this.objectTypes = this.route.snapshot.data.objectTypes;
  }

  get type() {
    return this.objectTypes.find(ot => ot.id === this.object.type_id);
  }

}
