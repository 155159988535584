import { OnInit } from '@angular/core';
import { ObjectPermissions, UserProfileService } from 'wsl-ek-core';
import { ActivatedRoute } from '@angular/router';
var ObjectRowComponent = /** @class */ (function () {
    function ObjectRowComponent(route, userProfileService) {
        this.route = route;
        this.userProfileService = userProfileService;
        this.disabled = true;
        /** @internal */
        this.objectTypes = [];
    }
    ObjectRowComponent.prototype.ngOnInit = function () {
        this.disabled = !this.userProfileService.hasPermission(ObjectPermissions.update);
        this.objectTypes = this.route.snapshot.data.objectTypes;
    };
    Object.defineProperty(ObjectRowComponent.prototype, "type", {
        get: function () {
            var _this = this;
            return this.objectTypes.find(function (ot) { return ot.id === _this.object.type_id; });
        },
        enumerable: true,
        configurable: true
    });
    return ObjectRowComponent;
}());
export { ObjectRowComponent };
