import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { WSLFormHelper } from 'wsl-core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { IWSLObject } from '@app/object/models/object';
import { ActivatedRoute } from '@angular/router';
import { ConnectionTypes } from '../../enums/connection-type.enum';
import { IWSLCommunicatorFilter } from '../../models/communicator-filter';

@Component({
  selector: 'wsl-b2b-communicator-list-filter',
  templateUrl: './communicator-list-filter.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunicatorListFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filter: IWSLCommunicatorFilter;
  @Input() objects: IWSLObject[];
  @Output() changed = new EventEmitter<IWSLCommunicatorFilter>();
  /** @internal */
  form = new FormGroup({
    object_id: new FormControl(null),
    connect_ids: new FormControl([]),
    search: new FormControl(''),
    offset: new FormControl(0)
  });

  /** @internal */
  connections = ConnectionTypes;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(750),
        // delay(150),
        distinctUntilChanged()
      )
      .subscribe(val => {
        if (this.form.dirty) {
          this.changed.emit(val);
        }
      });
  }

  ngOnChanges(changes) {
    if (changes.filter || changes.objects) {
      this.fillForm();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onSelectConnection(connect_ids) {
    this.form.get('connect_ids').setValue(connect_ids);
    this.form.markAsDirty();
  }

  private fillForm() {
    if (this.filter) {
      let dirty = false;
      if (this.filter.object_id && !this.form.get('object_id').value) {
        dirty = true;
      }
      WSLFormHelper.fillForm(this.form, this.filter);
      if (this.objects && this.objects.length > 0 && !this.form.get('object_id').value) {
        this.form.get('object_id').setValue(this.objects[0].id);
        dirty = true;
      }
      this.form.markAsPristine();
      this.form.markAsUntouched();
      if (dirty) {
        this.changed.emit(this.form.value);
      }
    }
  }
}
