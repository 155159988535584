import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WSLRouterHelperService, WSLUtils } from 'wsl-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { MenuHelperService } from '@core/services/menu-helper.service';
import { DeviceModelService } from '@app/device/services/device-model.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { AllResources, IWSLResourceUnit, IWSLResourceValue, IWSLResourceVariable, UserProfileService } from 'wsl-ek-core';
import { DeviceActionButtonType, HideDeviceButtonType } from '@app/device/enums/device-action-button';
import { DeviceExecutionService } from '@app/device/services/device-execution.service';
import { DeviceSensorService } from '@app/device-sensor/services/device-sensor.service';
import { combineLatest, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
var AddDeviceStage;
(function (AddDeviceStage) {
    AddDeviceStage[AddDeviceStage["readonly"] = -1] = "readonly";
    AddDeviceStage[AddDeviceStage["selectPlacementType"] = 1] = "selectPlacementType";
    AddDeviceStage[AddDeviceStage["selectLocation"] = 2] = "selectLocation";
    AddDeviceStage[AddDeviceStage["barcodeInput"] = 3] = "barcodeInput";
    AddDeviceStage[AddDeviceStage["selectCommunicator"] = 4] = "selectCommunicator";
    AddDeviceStage[AddDeviceStage["setupMeteringNode"] = 5] = "setupMeteringNode";
    // selectConfiguration = 6,
    AddDeviceStage[AddDeviceStage["inputServiceOperation"] = 6] = "inputServiceOperation";
    AddDeviceStage[AddDeviceStage["activeServiceProcedure"] = 7] = "activeServiceProcedure";
})(AddDeviceStage || (AddDeviceStage = {}));
var AddDeviceGuideComponent = /** @class */ (function () {
    function AddDeviceGuideComponent(route, menuService, userProfileService, sensorService, chr) {
        this.route = route;
        this.menuService = menuService;
        this.userProfileService = userProfileService;
        this.sensorService = sensorService;
        this.chr = chr;
        this.disabledDevice = true;
        this.disabledRoom = true;
        this.disabledCommunicator = true;
        this.disabledSensor = true;
        this.disabledServiceProcedure = true;
        this.loadObjects = new EventEmitter();
        this.loadObject = new EventEmitter();
        this.loadRooms = new EventEmitter();
        this.loadRoom = new EventEmitter();
        this.clearRooms = new EventEmitter();
        this.addRoom = new EventEmitter();
        this.addDevice = new EventEmitter();
        this.setDeviceCommunicator = new EventEmitter();
        this.setDeviceConfGroup = new EventEmitter();
        this.inputDevice = new EventEmitter();
        this.repairDevice = new EventEmitter();
        this.correctDevice = new EventEmitter();
        this.verifyDevice = new EventEmitter();
        this.reviseDevice = new EventEmitter();
        this.outputDevice = new EventEmitter();
        this.renameDevice = new EventEmitter();
        this.hideDevice = new EventEmitter();
        this.loadCommunicators = new EventEmitter();
        this.addCommunicator = new EventEmitter();
        this.loadDeviceSensors = new EventEmitter();
        this.updateDeviceSensors = new EventEmitter();
        // @Output() upsertDeviceSensors = new EventEmitter<IWSLDeviceSensor[]>();
        // @Output() deleteDeviceSensors = new EventEmitter<number[]>();
        this.loadConfGroups = new EventEmitter();
        this.loadConfGroup = new EventEmitter();
        this.loadConfs = new EventEmitter();
        this.loadConfData = new EventEmitter();
        this.loadFileIndexes = new EventEmitter();
        this.loadFileIndex = new EventEmitter();
        this.visibleStage = 1;
        this.stageType = AddDeviceStage;
        this.inputs = [];
        this.navMenu = [];
        this.actionButtons = [];
        this.showCorrectionBtn = false;
        this.isHome = environment.home;
        this.dmodels = [];
        this.dexecutions = [];
        this.rvariables = [];
        this.rvalues = [];
        this.runits = [];
        this.filledInputs = null;
    }
    AddDeviceGuideComponent.prototype.ngOnInit = function () {
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.dmodels = data.dmodels;
        this.dexecutions = data.dexecutions;
        this.rvariables = data.resourceVariables;
        this.rvalues = data.resourceValues;
        this.runits = data.resourceUnits;
    };
    AddDeviceGuideComponent.prototype.ngOnChanges = function (changes) {
        if (changes.device) {
            this.defineDevice();
            this.defineStage();
            this.deviceActionsAndNavs();
        }
        if (changes.deviceSensors) {
            this.deviceActionsAndNavs();
            this.fixFilledInputs();
        }
        if (changes.serviceProcedures) {
            this.checkProcedures();
        }
    };
    Object.defineProperty(AddDeviceGuideComponent.prototype, "canReturnToEditCommunicator", {
        get: function () {
            return this.device && this.device.communicators && this.device.communicators.length > 0 && !this.device.communicators[0].network.on_board;
        },
        enumerable: true,
        configurable: true
    });
    AddDeviceGuideComponent.prototype.defineStage = function () {
        if (!this.device) {
            if (environment.home) {
                this.device = { individual: true, room_id: this.userProfileService.getRoomID() };
                this.visibleStage = AddDeviceStage.barcodeInput;
                return;
            }
            this.visibleStage = AddDeviceStage.selectPlacementType;
            return;
        }
        if (!!this.device.group_id) {
            this.visibleStage = AddDeviceStage.inputServiceOperation;
            this.loadConfGroup.emit(this.device.group_id);
            if (!this.device.individual && !!this.device.group_file_id) {
                this.loadFileIndex.emit(this.device.group_file_id);
            }
        }
        else if (!this.device.communicators) {
            this.visibleStage = AddDeviceStage.selectCommunicator;
            this.loadCommunicators.emit({ on_board: false, object_id: this.device.object_id });
        }
        else {
            this.visibleStage = AddDeviceStage.setupMeteringNode;
            this.loadConfGroups.emit({
                dmodel_ids: [this.device.dmodel_id],
                resource_ids: this.inputs.filter(function (i) { return !!i.resource_id; }).map(function (i) { return i.resource_id; }),
                protocols: this.device.communicators.map(function (c) { return c.vr; })
            });
        }
        if (this.device.object_id) {
            this.loadObject.emit(this.device.object_id);
        }
        if (this.device.room_id) {
            this.loadRoom.emit(this.device.room_id);
        }
        this.loadDeviceSensors.emit({ device_id: this.device.id });
        if (!!this.device.date_input) {
            this.visibleStage = 0;
        }
        if (!!this.disabledDevice) {
            this.visibleStage = AddDeviceStage.readonly;
        }
        this.checkProcedures();
    };
    AddDeviceGuideComponent.prototype.onSelectPlacementType = function (ipu) {
        this.device = { individual: ipu };
        this.visibleStage = AddDeviceStage.selectLocation;
        this.loadObjects.emit();
    };
    AddDeviceGuideComponent.prototype.onAddRoom = function (room) {
        this.addRoom.emit(room);
    };
    AddDeviceGuideComponent.prototype.onSelectLocation = function (_a) {
        var object_id = _a.object_id, room_id = _a.room_id;
        var object = this.objects ? this.objects.find(function (o) { return o.id === object_id; }) : null;
        var room = this.rooms ? this.rooms.find(function (r) { return r.id === room_id; }) : null;
        this.device = tslib_1.__assign({}, this.device, { object_id: object_id, object_name: object ? object.name : '', room_id: room_id, room_name: room ? room.name : '' });
        this.visibleStage = AddDeviceStage.barcodeInput;
    };
    AddDeviceGuideComponent.prototype.onBarcodeInput = function (barcode) {
        this.device = tslib_1.__assign({}, this.device, { barcode: barcode });
        this.addDevice.emit(this.device);
    };
    AddDeviceGuideComponent.prototype.onSelectSchema = function (schema) {
        this.schema = tslib_1.__assign({}, schema);
    };
    AddDeviceGuideComponent.prototype.onSetupMeteringNode = function (_a) {
        var inputs = _a.inputs, schema = _a.schema;
        this.inputs = inputs.slice();
    };
    AddDeviceGuideComponent.prototype.onSelectConfGroup = function (_a) {
        var group = _a.group, fileIndex = _a.fileIndex;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deleted, upserted, sensors, dmodel, _b;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.deleteSensors()];
                    case 1:
                        deleted = _c.sent();
                        return [4 /*yield*/, this.upsertSensors(this.confData.filter(function (c) { return group.dconf_ids.includes(c.dconf_id); }))];
                    case 2:
                        upserted = _c.sent();
                        sensors = [];
                        if (!(deleted || upserted)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.sensorService
                                .getMany({ device_id: this.device.id })
                                .pipe(take(1), map(function (res) { return res.items; }), catchError(function (err) { return of([]); }))
                                .toPromise()];
                    case 3:
                        sensors = _c.sent();
                        _c.label = 4;
                    case 4:
                        this.inputs = [];
                        if (!this.device.group_id || this.device.group_id !== group.id ||
                            (fileIndex ? this.device.group_file_id !== fileIndex.id : !!this.device.group_file_id)) {
                            dmodel = this.dmodels.find(function (dm) { return dm.id === _this.device.dmodel_id; });
                            if (DeviceModelService.isKarat300(dmodel) && !fileIndex) {
                                WSLMaterializeHelper.toast({ html: 'Выберите карту параметров!' });
                                return [2 /*return*/];
                            }
                            this.setDeviceConfGroup.emit(tslib_1.__assign({}, this.device, { group_id: group.id, group_file_id: fileIndex ? fileIndex.id : null }));
                        }
                        else {
                            this.visibleStage = AddDeviceStage.inputServiceOperation;
                            this.chr.markForCheck();
                        }
                        if (deleted || upserted) {
                            this.updateDeviceSensors.emit(sensors);
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        _b = _c.sent();
                        WSLMaterializeHelper.toast({ html: 'Произошла ошибка работы с сенсорами. Обратитесь в техподдержку' });
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AddDeviceGuideComponent.prototype.onBack = function () {
        var _this = this;
        /*if (this.visibleStage === AddDeviceStage.selectConfiguration) {
          // @todo not allowed!
          // this.updateDevice.emit({...this.device, group_id: null});
          if (this.filledInputs && this.filledInputs.length > 0) {
            this.inputs = [];
            this.filledInputs = [];
          }
          this.visibleStage--;
        } else*/
        if (this.visibleStage === AddDeviceStage.setupMeteringNode) {
            if (this.canReturnToEditCommunicator) {
                this.visibleStage--;
            }
        }
        else if (this.visibleStage === AddDeviceStage.inputServiceOperation) {
            this.loadConfGroups.emit({
                dmodel_ids: [this.device.dmodel_id],
                protocols: this.device.communicators.map(function (c) { return c.vr; })
            });
            if (this.inputs.length === 0) {
                if (this.filledInputs && this.filledInputs.length > 0) {
                    this.inputs = this.filledInputs.slice();
                }
                else if (this.deviceSensors) {
                    this.inputs = this.deviceSensors.map(function (s) { return ({
                        sensor_id: s.id,
                        resource_id: s.resource_id,
                        resource: AllResources.find(function (r) { return r.id === s.resource_id; }),
                        resource_inx: s.resource_inx,
                        rvalue_id: s.rvalue_id,
                        rvalue: _this.rvalues.find(function (rv) { return rv.id === s.rvalue_id; }),
                        rvariable_id: _this.rvalues.find(function (rv) { return rv.id === s.rvalue_id; }).rvariable_id,
                        rvariable: _this.rvariables.find(function (rv) { return rv.id === _this.rvalues.find(function (rval) { return rval.id === s.rvalue_id; }).rvariable_id; }),
                        unit: _this.runits.find(function (unit) { return unit.rvariable_id === _this.rvalues.find(function (rv) { return rv.id === s.rvalue_id; }).rvariable_id; }),
                        entry: s.entry,
                        serial_num: s.serial_num,
                        date_next: s.date_next,
                        impulse_weight: s.impulse_weight,
                        device_id: s.device_id,
                        dconf_ext_id: s.dconf_ext_id
                    }); });
                }
            }
            this.visibleStage--;
        }
    };
    AddDeviceGuideComponent.prototype.onAddCommunicator = function (communicator) {
        this.addCommunicator.emit(communicator);
    };
    AddDeviceGuideComponent.prototype.onSelectCommunicator = function (_a) {
        var communicator_id = _a.communicator_id, modbus_addr = _a.modbus_addr;
        this.setDeviceCommunicator.emit({ id: this.device.id, communicator_ids: [communicator_id], modbus_addr: modbus_addr });
    };
    AddDeviceGuideComponent.prototype.onChangeModbus = function (modbus_addr) {
        this.setDeviceCommunicator.emit({ id: this.device.id, communicator_ids: this.device.communicator_ids, modbus_addr: modbus_addr });
    };
    AddDeviceGuideComponent.prototype.onInputDevice = function () {
        var _this = this;
        if (!!this.device && !!this.device.id && !!this.device.communicators && !!this.device.group_id) {
            if (this.serviceProcedures && this.serviceProcedures.length > 0 && this.serviceProcedures.some(function (p) { return !p.close && !p.cancel; })) {
                this.visibleStage = AddDeviceStage.activeServiceProcedure;
                return;
            }
            var deviceConfGroup = this.confGroups.find(function (g) { return g.id === _this.device.group_id; });
            var externalResources_1 = this.dexecution.resources.filter(function (r) { return !_this.dexecution.resources_in.includes(r); });
            var confGroupResources = !!deviceConfGroup.ext.resources ?
                deviceConfGroup.ext.resources.filter(function (r) { return externalResources_1.includes(r.resource_id); }) : [];
            var dmodel = this.dmodels.find(function (m) { return m.id === _this.device.dmodel_id; });
            var isCalc = DeviceModelService.isCalc(dmodel);
            if (!isCalc) {
                if (!!deviceConfGroup.ext.resources && !this.deviceSensors && confGroupResources.length > 0) {
                    WSLMaterializeHelper.toast({ html: 'Не заданы входы для выбранной конфигурации' });
                    return;
                }
                if (!deviceConfGroup.ext.resources && !!this.deviceSensors && this.deviceSensors.length > 0) {
                    WSLMaterializeHelper.toast({ html: 'Заданы входы лишние для выбранной конфигурации' });
                    return;
                }
                if (!!deviceConfGroup.ext.resources && !!this.deviceSensors && confGroupResources.length !== this.deviceSensors.length) {
                    WSLMaterializeHelper.toast({ html: 'Количество ресурсов конфигурации не соответствуют количеству ресурсов заведенных входов' });
                    return;
                }
                if (!!deviceConfGroup.ext.resources && !!this.deviceSensors &&
                    WSLUtils.difference(this.deviceSensors.map(function (s) { return s.resource_id; }), confGroupResources.map(function (r) { return r.resource_id; })).length !== 0) {
                    WSLMaterializeHelper.toast({ html: 'Ресурсы конфигурации не соответствуют ресурсам заведенных входов' });
                    return;
                }
            }
            if (this.deviceSensors.some(function (i) { return !!_this.deviceSensors.find(function (j) { return j.serial_num !== i.serial_num && i.entry === j.entry; }); })) {
                WSLMaterializeHelper.toast({ html: 'Датчики должны быть подключены на разные входы' });
                return;
            }
            this.inputDevice.emit(this.device);
        }
    };
    AddDeviceGuideComponent.prototype.onRepairDevice = function () {
        this.repairDevice.emit(this.device);
    };
    AddDeviceGuideComponent.prototype.onCorrectDevice = function () {
        this.correctDevice.emit(this.device);
    };
    AddDeviceGuideComponent.prototype.onVerifyDevice = function () {
        this.verifyDevice.emit(this.device);
    };
    AddDeviceGuideComponent.prototype.onReviseDevice = function () {
        this.reviseDevice.emit(this.device);
    };
    AddDeviceGuideComponent.prototype.onOutputDevice = function () {
        this.outputDevice.emit(this.device);
    };
    AddDeviceGuideComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    AddDeviceGuideComponent.prototype.checkProcedures = function () {
        if (this.serviceProcedures && this.serviceProcedures.length > 0) {
            if (this.serviceProcedures.some(function (p) { return !p.close && !p.cancel; })) {
                this.visibleStage = AddDeviceStage.activeServiceProcedure;
                this.activeServiceProcedure = this.serviceProcedures.find(function (p) { return !p.close && !p.cancel; });
            }
            this.deviceActionsAndNavs();
        }
    };
    AddDeviceGuideComponent.prototype.deviceActionsAndNavs = function () {
        this.actionButtons = [];
        this.showCorrectionBtn = false;
        this.navMenu = [];
        if (!!this.device && this.serviceProcedures && this.serviceProcedures.length > 0) {
            this.navMenu = this.menuService.getDeviceNav(this.device);
            this.showCorrectionBtn = !!this.device.date_input && !this.device.date_output &&
                !DeviceModelService.isCalc(DeviceModelService.getModel(this.device, this.dmodels)) &&
                this.deviceSensors && this.deviceSensors.length > 0 && this.device.communicators.every(function (c) { return parseFloat(c.vr) >= 2.0; });
        }
        if (!!this.device && !this.device.date_input && !!this.device.group_id && !this.activeServiceProcedure && !this.disabledDevice) {
            this.actionButtons.push(tslib_1.__assign({}, HideDeviceButtonType));
        }
        this.chr.markForCheck();
    };
    AddDeviceGuideComponent.prototype.onActionButtonClick = function (btn) {
        if (btn === DeviceActionButtonType.hide_device) {
            this.hideDevice.emit(this.device);
        }
    };
    AddDeviceGuideComponent.prototype.onVisibleAddCommunicator = function (visible) {
        if (visible) {
            this.actionButtons = this.actionButtons.map(function (b) { return (tslib_1.__assign({}, b, { visible: false })); });
        }
        else {
            this.actionButtons = this.actionButtons.map(function (b) { return (tslib_1.__assign({}, b, { visible: true })); });
        }
    };
    AddDeviceGuideComponent.prototype.defineDevice = function () {
        this.dexecution = DeviceExecutionService.getExecution(this.device, this.dexecutions);
    };
    AddDeviceGuideComponent.prototype.fixFilledInputs = function () {
        var _this = this;
        if (this.deviceSensors && this.deviceSensors.length > 0 && this.filledInputs && this.filledInputs.length > 0) {
            this.filledInputs = this.filledInputs.map(function (input) {
                var sensor = _this.deviceSensors.find(function (s) { return s.serial_num === input.serial_num; });
                if (sensor && !input.sensor_id) {
                    return tslib_1.__assign({}, input, { sensor_id: sensor.id });
                }
                return input;
            });
        }
    };
    AddDeviceGuideComponent.prototype.deleteSensors = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deleteSensors;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deleteSensors = DeviceSensorService.defineDeleteSensors(this.inputs, this.deviceSensors);
                        if (!(deleteSensors.length > 0)) return [3 /*break*/, 2];
                        if (!(deleteSensors.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, combineLatest(deleteSensors.map(function (id) { return _this.sensorService.delete(id).pipe(take(1)); }))
                                .pipe(take(1), map(function (res) { return true; }), catchError(function (err) { return of(false); }))
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/, false];
                }
            });
        });
    };
    AddDeviceGuideComponent.prototype.upsertSensors = function (confs) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, unknownSensors, upsertSensors;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = DeviceSensorService.defineUpsertSensors(this.inputs, this.deviceSensors, confs), unknownSensors = _a.unknownSensors, upsertSensors = _a.upsertSensors;
                        console.log('upser', this.inputs, upsertSensors);
                        if (unknownSensors.length > 0) {
                            WSLMaterializeHelper.toast({
                                html: "\u041D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u043E\u0434\u043D\u043E\u0437\u043D\u0430\u0447\u043D\u043E \u043E\u043F\u0435\u0440\u0435\u0434\u0438\u043B\u0438\u0442\u044C \u0441\u0432\u044F\u0437\u044C " + (unknownSensors.length > 1 ? 'датчиков' : 'датчика') + ":\n           " + unknownSensors.map(function (s) { return s.serial_num; }) + " \u0438 \u043A\u043E\u043D\u0444\u0438\u0433\u0443\u0440\u0430\u0446\u0438\u0438 \u041F\u0423.\n         \u0421\u0432\u044F\u0436\u0438\u0442\u0435\u0441\u044C \u0441 \u0442\u0435\u0445\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u043E\u0439"
                            });
                            throw new Error('invalid sensors or configuration');
                        }
                        if (!(upsertSensors.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sensorService.upsertMany(upsertSensors)
                                .pipe(take(1), map(function (res) { return true; }), catchError(function (err) { return of(false); }))
                                .toPromise()];
                    case 1: return [2 /*return*/, _b.sent()];
                    case 2: return [2 /*return*/, false];
                }
            });
        });
    };
    return AddDeviceGuideComponent;
}());
export { AddDeviceGuideComponent };
