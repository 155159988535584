import {Injectable} from '@angular/core';
import {IWSLEntityFilter, WSLApiHttpClientService} from 'wsl-core';
import {HttpClient} from '@angular/common/http';
import {IWSLSensorModel} from '@app/device-sensor/models/sensor-model.model';

@Injectable()
export class SensorModelService extends WSLApiHttpClientService<IWSLSensorModel, IWSLEntityFilter> {

  constructor(public http: HttpClient) {
    super(http, 'sensor_model');
  }
}
