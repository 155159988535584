import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {IWSLSensorModel} from '@app/device-sensor/models/sensor-model.model';
import {SensorModelTypes} from '@app/device-sensor/enums/sensor-model-type.enum';
import {WSLFormHelper} from 'wsl-core';
import {WSLMaterializeHelper} from 'wsl-shared';

@Component({
  selector: 'wsl-sensor-model',
  templateUrl: './sensor-model.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SensorModelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() smodel: IWSLSensorModel;
  @Input() formErrors: any;
  @Input() readonly: boolean;
  @Output() saveModel = new EventEmitter<IWSLSensorModel>();
  @Output() cancel = new EventEmitter();

  form = new FormGroup({
    id: new FormControl(null),
    name: new FormControl('', [Validators.required]),
    type_id: new FormControl(null, [Validators.required]),
    ep_model_id: new FormControl(null),
    enable: new FormControl(false),
  });


  types = SensorModelTypes;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private chr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.fillForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.smodel) {
      this.fillForm();
    }
    if (changes.readonly) {
      if (this.readonly) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.chr.detach();
  }

  onSave() {
    if (this.form.valid) {
      if (this.form.dirty) {
        const data = this.form.value;
        if (data.ep_model_id) {
          data.ep_model_id = data.ep_model_id.split(',');
        }
        this.saveModel.emit(this.form.value);
      } else {
        this.onCancel();
      }
    } else {
      if (this.form.get('name').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите название'});
      } else if (this.form.get('type_id').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите тип'});
      } else if (this.form.get('ep_model_id').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите соответствие в ЭП'});
      } else {
        WSLMaterializeHelper.toast({html: 'Ошибки в форме'});
      }
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  private fillForm() {
    if (this.smodel) {
      WSLFormHelper.fillForm(this.form, this.smodel);
      if (this.smodel.ep_model_id) {
        this.form.get('ep_model_id').setValue(this.smodel.ep_model_id.join(','));
      }
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }
  }

}
