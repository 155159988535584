import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WSLApiHttpClientService, WSLUtils } from 'wsl-core';
var DeviceSensorService = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceSensorService, _super);
    function DeviceSensorService(http) {
        var _this = _super.call(this, http, 'sensor') || this;
        _this.http = http;
        return _this;
    }
    DeviceSensorService.defineDeleteSensors = function (inputs, sensors) {
        return inputs.length > 0 ? sensors
            .filter(function (s) { return !inputs.find(function (i) { return i.sensor_id === s.id; }); })
            .map(function (s) { return s.id; }) : sensors.map(function (s) { return s.id; });
    };
    DeviceSensorService.defineUpsertSensors = function (inputs, sensors, confExts) {
        var unknownSensors = [];
        var upsertSensors = inputs.map(function (input) {
            var confs = confExts.filter(function (c) {
                return c.is_sensor &&
                    c.resource_id === input.resource_id &&
                    c.rvariable_id === input.rvariable.id &&
                    c.rvalue_id === input.rvalue_id &&
                    c.resource_inx === input.resource_inx;
            });
            if (confs.length === 1) {
                input.dconf_ext_id = confs[0].id;
                if (input.sensor_id) {
                    var sensor = sensors.find(function (s) { return s.id === input.sensor_id; });
                    /*  if (sensor.dconf_ext_id !== input.dconf_ext_id) {
                        return {
                          ...sensor,
                          serial_num: input.serial_num,
                          date_next: input.date_next,
                          impulse_weight: input.impulse_weight,
                          entry: input.entry,
                          resource_id: input.resource_id,
                          rvalue_id: input.rvalue_id,
                          resource_inx: input.resource_inx,
                          dconf_ext_id: input.dconf_ext_id
                        };
                      } else if (sensor.serial_num !== input.serial_num || sensor.date_next !== input.date_next ||
                        sensor.resource_inx !== input.resource_inx || sensor.entry !== input.entry ||
                        sensor.impulse_weight !== input.impulse_weight || sensor.rvalue_id !== input.rvalue_id) {
                        return {
                          ...sensor,
                          serial_num: input.serial_num,
                          date_next: input.date_next,
                          impulse_weight: input.impulse_weight,
                          entry: input.entry,
                          resource_id: input.resource_id,
                          rvalue_id: input.rvalue_id,
                          resource_inx: input.resource_inx,
                          dconf_ext_id: input.dconf_ext_id
                        };
                      }*/
                    if (WSLUtils.isDifferentObject(DeviceSensorService.inputToSensor(input), sensor)) {
                        return tslib_1.__assign({}, sensor, { serial_num: input.serial_num, date_next: input.date_next, impulse_weight: input.impulse_weight, entry: input.entry, resource_id: input.resource_id, rvalue_id: input.rvalue_id, resource_inx: input.resource_inx, dconf_ext_id: input.dconf_ext_id, smodel_id: input.smodel_id });
                    }
                    return null;
                }
                else {
                    return {
                        device_id: input.device_id,
                        serial_num: input.serial_num,
                        smodel_id: input.smodel_id,
                        date_next: input.date_next,
                        impulse_weight: input.impulse_weight,
                        entry: input.entry,
                        resource_id: input.resource_id,
                        rvalue_id: input.rvalue_id,
                        resource_inx: input.resource_inx,
                        dconf_ext_id: input.dconf_ext_id
                    };
                }
            }
            else {
                console.log('unkn', input, confs, confExts.filter(function (c) { return c.is_sensor &&
                    c.resource_id === input.resource_id; }));
                unknownSensors.push(input);
                // @todo
                return null;
            }
        }).filter(function (s) { return !!s; });
        return { unknownSensors: unknownSensors, upsertSensors: upsertSensors };
    };
    DeviceSensorService.inputToSensor = function (input) {
        return {
            id: input.sensor_id,
            serial_num: input.serial_num,
            smodel_id: input.smodel_id,
            resource_id: input.resource_id,
            resource_inx: input.resource_inx,
            rvalue_id: input.rvalue_id,
            entry: input.entry,
            impulse_weight: input.impulse_weight,
            date_next: input.date_next,
            device_id: input.device_id,
            dconf_ext_id: input.dconf_ext_id,
            rvariable_id: input.rvariable_id
        };
    };
    DeviceSensorService.prototype.upsertMany = function (sensors) {
        return this.http.post('upsert_sensors', { items: sensors });
    };
    return DeviceSensorService;
}(WSLApiHttpClientService));
export { DeviceSensorService };
