import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var ObjectCardComponent = /** @class */ (function () {
    function ObjectCardComponent(route) {
        this.route = route;
        /** @internal */
        this.objectTypes = [];
    }
    ObjectCardComponent.prototype.ngOnInit = function () {
        this.objectTypes = this.route.snapshot.data.objectTypes;
    };
    Object.defineProperty(ObjectCardComponent.prototype, "type", {
        get: function () {
            var _this = this;
            return this.object && this.objectTypes ? this.objectTypes.find(function (ot) { return ot.id === _this.object.type_id; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ObjectCardComponent.prototype, "showAddress", {
        get: function () {
            return this.object && this.object.name !== (this.object.street + ' ' + this.object.house_num);
        },
        enumerable: true,
        configurable: true
    });
    return ObjectCardComponent;
}());
export { ObjectCardComponent };
