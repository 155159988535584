import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { IWSLSensorModel} from '../../models/sensor-model.model';
import {SensorModelTypes} from '@app/device-sensor/enums/sensor-model-type.enum';

@Component({
  selector: 'wsl-sensor-models',
  templateUrl: './sensor-models.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SensorModelsComponent implements OnInit, OnChanges {
  @Input() smodels: IWSLSensorModel[];
  @Input() loading: boolean;
  @Input() error: string;
  @Input() formErrors: any;
  @Input() readonly: boolean;
  @Output() saveModel = new EventEmitter<IWSLSensorModel>();

  dataSource = new MatTableDataSource<IWSLSensorModel>([]);
  displayedColumns: string[] = ['name', 'type', 'ep', 'enable', 'actions'];

  smodel: IWSLSensorModel = null;
  showAdd = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.smodels && this.smodels) {
      this.dataSource.data = this.smodels;
      this.smodel = null;
      this.showAdd = false;
    }
  }

  type = (model: IWSLSensorModel) => SensorModelTypes.find(t => t.id === model.type_id);


  onShowAdd() {
    if (this.smodel) {
      this.smodel = null;
    }
    this.showAdd = true;
  }

  onShowEdit(smodel: IWSLSensorModel) {
    this.showAdd = false;
    if (!this.smodel || this.smodel && smodel && this.smodel.id !== smodel.id) {
      this.smodel = {...smodel};
    } else {
      this.smodel = null;
    }
  }

  trackById(index: number, item: IWSLSensorModel) {
    return item.id;
  }

}
