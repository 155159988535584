export enum SensorModelType {
  v = 1,
  p,
  t,
  magnit
}

export const SensorModelTypes = [
  {id: SensorModelType.v, name: 'V'},
  {id: SensorModelType.p, name: 'P'},
  {id: SensorModelType.t, name: 'T'},
  {id: SensorModelType.magnit, name: 'Контроль'}
];
