import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IWSLObject } from '../../models/object';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wsl-object-card',
  templateUrl: './object-card.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectCardComponent implements OnInit {
  @Input() object: IWSLObject;
  /** @internal */
  objectTypes: Array<{id: number, name: string}>  = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.objectTypes = this.route.snapshot.data.objectTypes;
  }

  get type() {
    return this.object && this.objectTypes ? this.objectTypes.find(ot => ot.id === this.object.type_id) : null;
  }

  get showAddress() {
    return this.object && this.object.name !== (this.object.street + ' ' + this.object.house_num);
  }
}
