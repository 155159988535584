import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeviceService } from 'wsl-device';
import { WSLMaterializeHelper } from 'wsl-shared';
var SelectDeviceComponent = /** @class */ (function () {
    function SelectDeviceComponent(deviceService, chr) {
        this.deviceService = deviceService;
        this.chr = chr;
        this.prefix = '';
        this.filter = {};
        this.selectItem = new EventEmitter();
        this.readonly = true;
        /** @internal */
        this.form = new FormGroup({
            device_name: new FormControl(''),
            device_id: new FormControl(null)
        });
        /** @internal */
        this.devices = [];
        /** @internal */
        this.selectedDeviceIndex = 0;
        /** @internal */
        this.deviceFocus = new EventEmitter();
        /** @internal */
        this.loadingDevices = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(SelectDeviceComponent.prototype, "disabled", {
        set: function (isDisable) {
            this.readonly = isDisable;
            if (isDisable) {
                this.form.disable();
            }
            else {
                this.form.enable();
            }
            this.form.updateValueAndValidity();
        },
        enumerable: true,
        configurable: true
    });
    SelectDeviceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.get('device_id').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(100), distinctUntilChanged())
            .subscribe(function (val) {
            _this.selectItem.emit(val);
        });
    };
    SelectDeviceComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.id) {
            if (this.id) {
                this.form.get('device_id').setValue(this.id);
                if (this.devices.length > 0) {
                    var d = this.devices.find(function (dev) { return dev.id === _this.id; });
                    this.form.get('device_name').setValue(d ? d.name : '');
                }
                else {
                    this.loadDevice();
                }
            }
        }
        if (changes.name) {
            if (this.form.get('device_name').value !== name) {
                this.form.get('device_name').setValue(this.name);
            }
        }
        if (changes.filter && this.devices.length > 0) {
            this.onClearDevice();
        }
    };
    SelectDeviceComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    // Autocomplete Device
    SelectDeviceComponent.prototype.onSearchDevice = function (search) {
        this.selectedDeviceIndex = 0;
        this.form.get('device_name').setValue(search);
        this.loadDeviceSuggestions(search);
        this.deviceFocus.emit(true);
    };
    SelectDeviceComponent.prototype.onEnterKeyDevice = function (keyCode) {
        switch (keyCode) {
            case 13:
                // enter
                this.onSelectDevice(this.devices[this.selectedDeviceIndex]);
                break;
            case 38:
                // up
                if (this.selectedDeviceIndex > 0) {
                    this.selectedDeviceIndex--;
                }
                break;
            case 40:
                // down
                if (this.selectedDeviceIndex < this.devices.length) {
                    this.selectedDeviceIndex++;
                }
                break;
            default:
                this.deviceFocus.emit(false);
        }
    };
    SelectDeviceComponent.prototype.onSelectDevice = function (val) {
        if (val) {
            this.selectedDeviceIndex = this.devices.indexOf(val);
            this.form.get('device_name').setValue(val.name);
            this.form.get('device_id').setValue(val.id);
            this.form.updateValueAndValidity();
            this.deviceFocus.emit(false);
        }
        else {
            this.onClearDevice();
        }
    };
    SelectDeviceComponent.prototype.onClearDevice = function () {
        this.selectedDeviceIndex = 0;
        this.form.get('device_name').setValue('');
        this.form.get('device_id').setValue(null);
        this.loadDeviceSuggestions('');
        this.form.updateValueAndValidity();
        this.deviceFocus.emit(false);
    };
    SelectDeviceComponent.prototype.loadDeviceSuggestions = function (search) {
        var _this = this;
        var match = /^№([0-9]+).*$/.exec(search);
        if (match) {
            search = match[1];
        }
        this.loadingDevices = true;
        this.devices = [];
        var filter = this.filter ? this.filter : {};
        this.deviceService.getMany(tslib_1.__assign({}, filter, { search: search, limit: 25, offset: 0 }))
            .subscribe(function (res) {
            if (!res.error) {
                _this.devices = res.items.map(function (val) { return (tslib_1.__assign({}, val, { name: "" + (val.alias ? val.alias : ('№' + val.serial_num)) })); })
                    .sort(function (a, b) { return DeviceService.sort(a, b); });
                _this.loadingDevices = false;
                _this.chr.markForCheck();
            }
        }, function (err) {
            _this.loadingDevices = false;
            _this.chr.markForCheck();
        });
    };
    SelectDeviceComponent.prototype.loadDevice = function () {
        var _this = this;
        this.loadingDevices = true;
        this.devices = [];
        this.deviceService.get(this.id)
            .subscribe(function (res) {
            if (!res.error) {
                _this.devices = [tslib_1.__assign({}, res.item, { name: "" + (res.item.alias ? res.item.alias : ('№' + res.item.serial_num)) })];
                _this.onSelectDevice(_this.devices[0]);
                _this.loadingDevices = false;
                _this.chr.markForCheck();
            }
        }, function (err) {
            WSLMaterializeHelper.toast({ html: _this.label + ' не найден. Возможно, он скрыт' });
            _this.loadingDevices = false;
            _this.chr.markForCheck();
        });
    };
    SelectDeviceComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    return SelectDeviceComponent;
}());
export { SelectDeviceComponent };
