import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { SensorModelTypes } from '@app/device-sensor/enums/sensor-model-type.enum';
var SensorModelsComponent = /** @class */ (function () {
    function SensorModelsComponent() {
        this.saveModel = new EventEmitter();
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = ['name', 'type', 'ep', 'enable', 'actions'];
        this.smodel = null;
        this.showAdd = false;
        this.type = function (model) { return SensorModelTypes.find(function (t) { return t.id === model.type_id; }); };
    }
    SensorModelsComponent.prototype.ngOnInit = function () {
    };
    SensorModelsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.smodels && this.smodels) {
            this.dataSource.data = this.smodels;
            this.smodel = null;
            this.showAdd = false;
        }
    };
    SensorModelsComponent.prototype.onShowAdd = function () {
        if (this.smodel) {
            this.smodel = null;
        }
        this.showAdd = true;
    };
    SensorModelsComponent.prototype.onShowEdit = function (smodel) {
        this.showAdd = false;
        if (!this.smodel || this.smodel && smodel && this.smodel.id !== smodel.id) {
            this.smodel = tslib_1.__assign({}, smodel);
        }
        else {
            this.smodel = null;
        }
    };
    SensorModelsComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    return SensorModelsComponent;
}());
export { SensorModelsComponent };
