import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IWSLCommunicator } from 'wsl-device';
import { IWSLObject } from '@app/object/models/object';

@Component({
  selector: 'wsl-communicator-list',
  templateUrl: './communicator-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunicatorListComponent implements OnInit {
  @Input() communicators: IWSLCommunicator[];
  @Input() objects: IWSLObject[];

  /** @internal */
  scrollTop = 0;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  onScroll(st) {
    this.scrollTop = st;
  }

  onSelect(id) {
    this.router.navigate(['./', id], {relativeTo: this.route});
  }

  trackById(index: number, obj: IWSLCommunicator) {
    return obj.id;
  }
}
