import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IWSLCommunicator } from 'wsl-device';
import { IWSLObject } from '@app/object/models/object';
import { ConnectionType } from '../../enums/connection-type.enum';
import { LoraNodeClasses } from '../../enums/lora-node-class.enum';
import { LoRaWANProtocols } from '../../enums/lorawan-protocol.enum';
import { CommunicatorService } from '../../services/communicator.service';

@Component({
  selector: '[wsl-communicator-row]',
  templateUrl: './communicator-row.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunicatorRowComponent implements OnInit {
  @Input() communicator: IWSLCommunicator;
  @Input() objects: IWSLObject[];

  constructor() { }

  ngOnInit() {
  }

  get object() {
    return this.communicator.object_id && this.objects ?
      this.objects.find(o => o.id === this.communicator.object_id) : null;
  }

  get isLora() {
    return this.communicator.network.channel_id === ConnectionType.lora;
  }

  get isTCP() {
    return this.communicator.network.channel_id === ConnectionType.tcp || this.communicator.network.channel_id === ConnectionType.tcp_gprs;
  }

  get isGPRS() {
    return this.communicator.network.channel_id === ConnectionType.gprs || this.communicator.network.channel_id === ConnectionType.tcp_gprs;
  }

  get loraNodeClass() {
    return this.communicator.network.lora.lora_class_id ? LoraNodeClasses.find(c => c.id === +this.communicator.network.lora.lora_class_id) : null;
  }

  get loraProtocolVersion() {
    return this.communicator.network.lora.lora_version_id ? LoRaWANProtocols.find(c => c.id === +this.communicator.network.lora.lora_version_id) : null;
  }

  get connection() {
    if (this.communicator) {
      const nt = CommunicatorService.getConnectionType(this.communicator);
      if (!nt) {
        console.error(this.communicator);
      } else {
        return {
          icon: nt.icon,
          name: nt.name,
          describe: CommunicatorService.getConnectionParameters(this.communicator)
        };
      }
    }
    return null;
  }


}
