import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { WSLMaterializeHelper } from 'wsl-shared';
var RoomListComponent = /** @class */ (function () {
    function RoomListComponent() {
        this.disabled = true;
        this.selectItem = new EventEmitter();
        this.add = new EventEmitter();
        this.massAdd = new EventEmitter();
        this.delete = new EventEmitter();
        this.massDelete = new EventEmitter();
        this.generateCodes = new EventEmitter();
        /** @internal */
        this.apartmentNumBeg = null;
        /** @internal */
        this.apartmentNumEnd = null;
        /** @internal */
        this.nonResidentialName = '';
    }
    RoomListComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(RoomListComponent.prototype, "apartments", {
        get: function () {
            return this.rooms ? this.rooms.filter(function (r) { return r.residential; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoomListComponent.prototype, "specials", {
        get: function () {
            return this.rooms ? this.rooms.filter(function (r) { return !r.residential; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    RoomListComponent.prototype.addApartments = function () {
        var _this = this;
        if (this.apartmentNumBeg == null || this.apartmentNumBeg < 1) {
            WSLMaterializeHelper.toast({ html: 'Задайте начало диапазона квартир' });
            return;
        }
        if (this.apartmentNumEnd == null || this.apartmentNumEnd < 1) {
            var id = this.apartments.findIndex(function (item) { return parseInt(item.name, 10) === _this.apartmentNumBeg; });
            if (id < 0) {
                this.addRoom({ name: this.apartmentNumBeg.toString(), residential: true });
            }
            this.apartmentNumBeg = null;
            return;
        }
        if (this.apartmentNumBeg > this.apartmentNumEnd) {
            WSLMaterializeHelper.toast({ html: 'Начало диапазона квартир должно быть меньше конца' });
            return;
        }
        var data = [];
        var _loop_1 = function (i) {
            if (this_1.apartments.findIndex(function (item) { return parseInt(item.name, 10) === i; }) < 0) {
                data.push({ name: i, residential: true });
            }
        };
        var this_1 = this;
        for (var i = this.apartmentNumBeg; i <= this.apartmentNumEnd; i++) {
            _loop_1(i);
        }
        this.addRooms(data);
        this.apartmentNumBeg = null;
        this.apartmentNumEnd = null;
    };
    RoomListComponent.prototype.removeApartments = function () {
        var _this = this;
        if (this.apartmentNumBeg == null || this.apartmentNumBeg < 1) {
            WSLMaterializeHelper.toast({ html: 'Задайте начало диапазона квартир' });
            return;
        }
        if (this.apartmentNumEnd == null || this.apartmentNumEnd < 1) {
            var app = this.apartments.find(function (item) { return item.name === _this.apartmentNumBeg.toString(10); });
            if (app) {
                this.deleteRoom(app.id);
            }
            this.apartmentNumBeg = null;
            return;
        }
        if (this.apartmentNumBeg > this.apartmentNumEnd) {
            WSLMaterializeHelper.toast({ html: 'Начало диапазона квартир должно быть меньше конца' });
            return;
        }
        var data = [];
        var _loop_2 = function (i) {
            var app = this_2.apartments.find(function (item) { return item.name === i.toString(10); });
            if (app) {
                data.push(app.id);
            }
        };
        var this_2 = this;
        for (var i = this.apartmentNumBeg; i <= this.apartmentNumEnd; i++) {
            _loop_2(i);
        }
        this.deleteRooms(data);
        this.apartmentNumBeg = null;
        this.apartmentNumEnd = null;
    };
    RoomListComponent.prototype.addNonResidential = function () {
        if (this.nonResidentialName !== '') {
            this.addRoom({ name: this.nonResidentialName, residential: false });
        }
        this.nonResidentialName = '';
    };
    RoomListComponent.prototype.removeNonResidential = function (item) {
        var room = this.specials.find(function (it) { return it.id === item.id; });
        if (room) {
            this.deleteRoom(room.id);
        }
    };
    RoomListComponent.prototype.generateActivationCodes = function () {
        if (this.object) {
            this.generateCodes.emit(this.object.id);
        }
    };
    RoomListComponent.prototype.addRoom = function (obj) {
        this.add.emit(tslib_1.__assign({}, obj, { object_id: this.object.id }));
    };
    RoomListComponent.prototype.addRooms = function (data) {
        var _this = this;
        this.massAdd.emit(data.map(function (room) { return (tslib_1.__assign({}, room, { object_id: _this.object.id })); }));
    };
    RoomListComponent.prototype.deleteRoom = function (id) {
        this.delete.emit(id);
    };
    RoomListComponent.prototype.deleteRooms = function (data) {
        this.massDelete.emit(data);
    };
    return RoomListComponent;
}());
export { RoomListComponent };
